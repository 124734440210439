<template>
  <v-select
    prepend-icon="mdi-clipboard-edit-outline"
    prefix="Benefício:"
    item-text="text"
    item-value="value"
    :items="servicos"
    :readonly="readonly"
    :value="servico"
    dense
    hide-details
    @change="onChange"
  ></v-select>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { BeneficiosEnum, BeneficiosLabels } from "@/core/enums/beneficios.js";

export default {
  created: function () {
    this.$emit("input", this.servico);
    this.$emit("change", this.servico);
  },
  computed: {
    ...mapGetters(["clientId", "clientServico", "servico"]),
    readonly: function () {
      return this.clientServico !== BeneficiosEnum.LEI_DO_BEM_E_INFORMATICA;
    },
    servicos: function () {
      if (this.clientServico === BeneficiosEnum.CAPTACAO) {
        return [
          {
            value: BeneficiosEnum.CAPTACAO,
            text: BeneficiosLabels.CAPTACAO,
          },
        ];
      }

      return [
        {
          value: BeneficiosEnum.LEI_DO_BEM,
          text: BeneficiosLabels.LEI_DO_BEM,
        },
        {
          value: BeneficiosEnum.LEI_DE_INFORMATICA,
          text: BeneficiosLabels.LEI_DE_INFORMATICA,
        },
      ];
    },
  },
  methods: {
    ...mapMutations(["setServico"]),
    onChange: function (event) {
      this.setServico(event);
      this.$emit("input", event);
      this.$emit("change", event);
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  name: "servico-select",
  props: {
    value: Number,
  },
  watch: {
    clientId: function () {
      this.$emit("input", this.servico);
      this.$emit("change", this.servico);
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-select::v-deep .v-select__selection--disabled {
  color: unset;
}
</style>
